import { getAnonymousId } from "@/services/analytics";
import publicIp from "public-ip";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/auth";

interface AuthObject {
  email: string;
  password: string;
  uid: string;
}

interface LoginObject {
  anonymousID: string;
  ip: string;
}

export async function login(uid: string): Promise<void> {
  const updateObject: LoginObject = {
    anonymousID: getAnonymousId(),
    ip: await publicIp.v4()
  };

  await learnlinkAPI.axios.put(
    `${ENDPOINT}/login/${uid}`,
    updateObject
  );
}

export async function updateAuthObject(authObject: AuthObject): Promise<AuthObject> {
  const authRes = await learnlinkAPI.axios.put<AuthObject>(
    `${ENDPOINT}/${authObject.uid}`,
    authObject
  );
  return authRes.data;
}

export async function doesEmailExist(email: string): Promise<boolean> {
  const authRes = await learnlinkAPI.axios.get<boolean>(
    `${ENDPOINT}/exists/${email}`,
  );
  return authRes.data;
}

export async function deleteUser(uid: string): Promise<AuthObject> {
  const authRes = await learnlinkAPI.axios.delete<AuthObject>(
    `${ENDPOINT}/${uid}`,
  );
  return authRes.data;
}

export async function deleteStudent(uid: string): Promise<AuthObject> {
  const authRes = await learnlinkAPI.axios.delete<AuthObject>(
    `${ENDPOINT}/student/${uid}`,
  );
  return authRes.data;
}
