/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export enum BaseRoutes {
  PARENT = "/forelder",
  STUDENT = "/elev",
  TUTOR = "/pl",
}

export const RoutesConfig = {
  CONTACT_ME: {
    name: "Kontakt meg",
    path: "/kontakt-meg",
    component: () => import("@/views/onboarding/ContactMe.vue"),
  },
  CONTACT_ME_TARGET_GROUP: {
    name: "Kontakt meg",
    path: "/kontakt-meg/:targetGroup",
    component: () => import("@/views/onboarding/ContactMe.vue"),
  },
  DISCORD_REDIRECT: {
    name: "Discord-tilkobling",
    path: "/discord-tilkobling",
    component: () => import("@/views/DiscordRedirect.vue"),
  },
  FEEDBACK: {
    name: "Tilbakemelding",
    path: "/t/:teamid/:uid",
    component: () => import("@/views/Feedback.vue"),
  },
  GOD_MODE: {
    name: "God mode",
    path: "/god-mode/:uid",
    component: () => import("@/views/GodMode.vue"),
  },
  LOGIN: {
    name: "Logg inn",
    path: "/logg-inn",
    component: () => import("@/views/Login.vue"),
  },
  ORDER_SELLER: {
    name: "Bestilling",
    path: "/bestilling",
    component: () => import("@/views/onboarding/AutomaticOnboarding.vue"),
  },
  ORDER_SELLER_TARGET_GROUP: {
    name: "Bestilling",
    path: "/bestilling/:targetGroup",
    component: () => import("@/views/onboarding/AutomaticOnboarding.vue"),
  },
  ORDER_SELLER_TARGET_GROUP_CUSTOMER_TYPE: {
    name: "Bestilling",
    path: "/bestilling/:targetGroup/:customerType",
    component: () => import("@/views/onboarding/AutomaticOnboarding.vue"),
  },
  ORDER_TEAM: {
    name: "Team-bestilling",
    path: "/team-bestilling/:timestamp/:courseID",
    component: () => import("@/views/onboarding/AutomaticTeamOnboarding.vue"),
  },
  REGISTER: {
    name: "Registrer",
    path: "/registrer",
    component: () => import("@/views/onboarding/sellers/Register.vue"),
  },
  COURSE_PICKER: {
    name: "Kurs-meny",
    path: "/kurs-meny/:courseID",
    component: () => import("@/views/onboarding/AvailableCoursePicker.vue"),
  },
  VIDEOS: {
    name: "Videoer",
    path: "/videoer",
    component: () => import("@/views/VideoLibrary.vue"),
  },
  SELLER_ACHIEVEMENTS: {
    name: "Achievements for kidsa",
    path: "/pl/achievements",
    component: () => import("@/views/seller/AchievementsSeller.vue"),
  },
  SELLER_DASHBOARD: {
    name: "Oversikt",
    path: "/pl/",
    component: () => import("@/views/seller/SellerDashboard.vue"),
  },
  SELLER_FEEDBACK: {
    name: "Tilbakemelding for Pro",
    path: "/t/pro/:teamid/:uid",
    component: () => import("@/views/seller/SellerFeedback.vue"),
  },
  SELLER_LESSONS: {
    name: "Kalender",
    path: "/pl/kalender",
    component: () => import("@/views/seller/LessonsSeller.vue"),
  },
  SELLER_NEWS: {
    name: "Siste nytt",
    path: "/pl/siste-nytt",
    component: () => import("@/views/seller/News.vue"),
  },
  SELLER_PAYOUTS: {
    name: "Utbetalinger",
    path: "/pl/utbetalinger",
    component: () => import("@/views/seller/Payouts.vue"),
  },
  SELLER_PROFILE: {
    name: "Profil",
    path: "/pl/profil/:userId?",
    getPath(userId: string): string {
      return `/pl/profil/${userId}`;
    },
    component: () => import("@/views/Profile.vue"),
  },
  SELLER_REPORTS: {
    name: "Rapporter",
    path: "/pl/rapporter",
    component: () => import("@/views/Reports.vue"),
  },
  SELLER_RESOURCES: {
    name: "Ressurser",
    path: "/pl/ressurser",
    component: () => import("@/views/seller/Resources.vue"),
  },
  SELLER_SETTINGS: {
    name: "Innstillinger",
    path: "/pl/innstillinger",
    component: () => import("@/views/Settings.vue"),
  },
  CUSTOMER_COURSE_SHOP: {
    name: "Kurskatalog",
    path: "/forelder/kurskatalog",
    // TODO: change to correct component when it is available
    component: () => import("@/views/customer/CustomerDashboard.vue"),
  },
  CUSTOMER_DASHBOARD: {
    name: "Oversikt",
    path: "/forelder/",
    component: () => import("@/views/customer/CustomerDashboard.vue"),
  },
  CUSTOMER_LESSONS: {
    name: "Kalender",
    path: "/forelder/kalender",
    component: () => import("@/views/customer/LessonsCustomer.vue"),
  },
  CUSTOMER_SETTINGS: {
    name: "Innstillinger",
    path: "/forelder/innstillinger",
    component: () => import("@/views/Settings.vue"),
  },
  CUSTOMER_MEMBERSHIP: {
    name: "Medlemskap",
    path: "/forelder/medlemskap",
    component: () => import("@/views/customer/Membership.vue"),
  },
  CUSTOMER_REPORTS: {
    name: "Rapporter",
    path: "/forelder/rapporter",
    component: () => import("@/views/Reports.vue"),
  },
  CUSTOMER_STUDENTS: {
    name: "Mine barn",
    path: "/forelder/mine-barn",
    component: () => import("@/views/customer/MyStudentsCustomer.vue"),
  },
  STUDENT_DASHBOARD: {
    name: "Oversikt",
    path: "/elev/",
    component: () => import("@/views/student/StudentDashboard.vue"),
  },
  STUDENT_LESSONS: {
    name: "Kalender",
    path: "/elev/kalender",
    component: () => import("@/views/customer/LessonsCustomer.vue"),
  },
  STUDENT_SETTINGS: {
    name: "Innstillinger",
    path: "/elev/innstillinger",
    component: () => import("@/views/Settings.vue"),
  },
};
