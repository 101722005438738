import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";
const ENDPOINT = "/app/teams";

export async function addStudentToTeam(teamID: string, uid: string): Promise<Learnlink.Team.default> {
  const teamRes = await learnlinkAPI.axios.put<Learnlink.Team.default>(
    `${ENDPOINT}/add-student/${uid}/${teamID}`,
  );

  return teamRes.data;
}

export async function deleteInfo(teamID: string, infoID: string): Promise<Learnlink.Team.default> {
  const teamRes = await learnlinkAPI.axios.delete<Learnlink.Team.default>(
    `${ENDPOINT}/${teamID}/info/${infoID}`
  );

  return teamRes.data;
}

export async function getMyTeams(uid: string): Promise<Learnlink.Team.FullMetadata[]> {
  const teamsRes = await learnlinkAPI.axios.get<Learnlink.Team.FullMetadata[]>(
    `${ENDPOINT}/${uid}`
  );

  return teamsRes.data;
}

export async function getTeamCount(): Promise<number> {
  const res = await learnlinkAPI.axios.get<number>(`${ENDPOINT}/count/${userModule.state.userId}`);
  return res.data;
}

export async function postInfo(teamID: string, info: Learnlink.TeamInformation.Create): Promise<Learnlink.Team.default> {
  const teamRes = await learnlinkAPI.axios.post<Learnlink.Team.default>(
    `${ENDPOINT}/${teamID}/info`,
    info
  );

  return teamRes.data;
}

export async function postOverrideRoomURLforTeam(teamID: string, URL: string): Promise<Learnlink.Team.default> {
  const teamRes = await learnlinkAPI.axios.put<Learnlink.Team.default>(
    `${ENDPOINT}/${teamID}/overrideRoomURL`,
    { url: URL }
  );
  return teamRes.data;
}

export async function signContract(teamID: string, uid: string): Promise<Learnlink.Team.default> {
  const teamRes = await learnlinkAPI.axios.post<Learnlink.Team.default>(
    `${ENDPOINT}/${teamID}/sign/${uid}`
  );

  return teamRes.data;
}
