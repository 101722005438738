

















































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { RoutesConfig } from "@/router/Routes";
import { useModule } from "@/store/hooks";
import { userModule } from "@/store/modules/user";
import { useRouter } from "@/router";
import authModule from "@/store/modules/auth";
import ForgotPasswordForm from "@/components/app/login/ForgotPasswordForm.vue";
import LoginForm from "@/components/app/login/LoginForm.vue";

export default defineComponent({
  components: { LoginForm, ForgotPasswordForm },
  name: "Login",
  setup() {
    const { currentRoute, navigate } = useRouter();
    const vm = reactive({
      step: 1,
      loading: true,
    });

    onMounted(() => {
      if (currentRoute.value.path !== RoutesConfig.LOGIN.path) {
        navigate(RoutesConfig.LOGIN.path);
      }
      const { state } = useModule(authModule);
      if (state.isAuthorized) {
        if (userModule.state.user.role === "seller") {
          navigate(RoutesConfig.SELLER_DASHBOARD.path);
        }
        else if (userModule.state.user.role === "student") {
          navigate(RoutesConfig.STUDENT_DASHBOARD.path);
        }
        else {
          navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
        }
      }
      else {
        vm.loading = false;
      }
    });

    return {
      vm
    };
  }
});
