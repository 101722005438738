import learnlinkAPI from "@/api/learnlinkAPI";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/app/teamSessions";

export async function getTeamSessions(teamID: string): Promise<Learnlink.TeamSession.default[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.TeamSession.default[]>(
    `${ENDPOINT}/${teamID}`
  );

  return res.data;
}

export async function getTeamSessionStartTimesForOnboardingByCourse(courseID: string): Promise<number[]> {
  const res = await learnlinkAPI.axios.get<number[]>(
    `app/customers/teamSessionStartTimesForOnboarding/${courseID}`
  );

  return res.data;
}

export async function getAllTeamSessionsForUser(uid: string): Promise<Learnlink.TeamSession.default[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.TeamSession.default[]>(
    `${ENDPOINT}/user/${uid}`
  );

  return res.data;
}

export async function updateTeamSession(teamSession: Learnlink.TeamSession.Update): Promise<Learnlink.TeamSession.default[]> {
  const res = await learnlinkAPI.axios.put<Learnlink.TeamSession.default[]>(
    `${ENDPOINT}/${teamSession.ID}`,
    teamSession
  );

  return res.data;
}

export async function updateAttendance(updateAttendanceObject: Learnlink.TeamSession.UpdateAttendance): Promise<Learnlink.TeamSession.default> {
  const res = await learnlinkAPI.axios.put<Learnlink.TeamSession.default>(
    `${ENDPOINT}/attendance`,
    updateAttendanceObject
  );
  return res.data;
}

export async function updatePerformance(updatePerformanceObject: Learnlink.TeamSession.UpdatePerformance): Promise<Learnlink.TeamSession.default> {
  const res = await learnlinkAPI.axios.put<Learnlink.TeamSession.default>(
    `${ENDPOINT}/performance`,
    updatePerformanceObject
  );
  return res.data;
}
