import { dynamicModule } from "../dynamicModuleUtil";
import { getIncompleteReportsCount } from "@/api/lessonService";
import { getNumberOfUnpaidInvoices } from "@/api/membershipService";
import { getProjectCount } from "@/api/projectService";
import { getTeamCount } from "@/api/teamService";
import { Getters, Mutations, Actions } from "vuex-smart-module";
import { getUnreadNewsMessagesCount } from "@/api/newsService";
import { userModule } from "./user";
import handleError from "@/helpers/errors";

interface UIStateInterface {
  incompleteReportsCount: number;
  projectCount: number;
  teamCount: number;
  unpaidInvoices: number;
  unreadNewsMessageCount: number;
}

class UIGetters extends Getters<UIStateInterface> {}

class UIMutations extends Mutations<UIStateInterface> {
  setIncompleteReportsCount(payload: number) {
    this.state.incompleteReportsCount = payload;
  }

  setTeamCount(payload: number) {
    this.state.teamCount = payload;
  }

  setProjectCount(payload: number) {
    this.state.projectCount = payload;
  }

  setUnreadNewsMessageCount(payload: number) {
    this.state.unreadNewsMessageCount = payload;
  }

  setUnpaidInvoices(payload: number) {
    this.state.unpaidInvoices = payload;
  }
}

class UIActions extends Actions<
  UIStateInterface,
  UIGetters,
  UIMutations,
  UIActions
  > {
  async fetchIncompleteReportsCount() {
    let incompleteReportsCount: number;
    try {
      incompleteReportsCount = await getIncompleteReportsCount();
      this.mutations.setIncompleteReportsCount(incompleteReportsCount);
    }
    catch (e) {
      handleError(e);
    }
  }

  async fetchTeamCount() {
    let count: number;
    try {
      count = await getTeamCount();
      this.mutations.setTeamCount(count);
    }
    catch (e) {
      handleError(e);
    }
  }

  async fetchProjectCount() {
    let count: number;
    try {
      count = await getProjectCount(userModule.state.userId);
      this.mutations.setProjectCount(count);
    }
    catch (e) {
      handleError(e);
    }
  }

  async fetchUnreadNewsMessageCount() {
    let unreadNewsMessageCount: number;
    try {
      unreadNewsMessageCount = await getUnreadNewsMessagesCount();
      this.mutations.setUnreadNewsMessageCount(unreadNewsMessageCount);
    }
    catch (e) {
      handleError(e);
    }
  }

  async fetchUnpaidInvoices() {
    let unpaidInvoices: number;
    try {
      unpaidInvoices = await getNumberOfUnpaidInvoices(userModule.state.userId);
      this.mutations.setUnpaidInvoices(unpaidInvoices);
    }
    catch (e) {
      handleError(e);
    }
  }
}

export const uiModule = dynamicModule<UIStateInterface, UIGetters, UIMutations, UIActions>(
  "dynamicUI", {
    getters: UIGetters,
    mutations: UIMutations,
    actions: UIActions,
  });
