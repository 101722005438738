import { RoutesConfig } from "@/router/Routes";
import { uiModule } from "@/store/modules/ui";
import { userModule } from "@/store/modules/user";

type MenuItem = {
  title: string;
  route: string;
  icon: string;
  actionText?: string;
  counter?: number;
  divider?: "top" | "bottom";
};
type MenuItems = MenuItem[];

export const getMenuItems = (showSellerRoutes: boolean, showStudentRoutes: boolean): MenuItems => {
  const commonRoutes: MenuItems = [
    {
      title: "Innstillinger",
      route: showSellerRoutes
        ? RoutesConfig.SELLER_SETTINGS.path
        : showStudentRoutes
          ? RoutesConfig.STUDENT_SETTINGS.path
          : RoutesConfig.CUSTOMER_SETTINGS.path,
      icon: "mdi-cog",
    },
  ];

  const sellerRoutes: MenuItems = [
    {
      title: RoutesConfig.SELLER_DASHBOARD.name,
      route: RoutesConfig.SELLER_DASHBOARD.path,
      icon: "mdi-view-dashboard",
    },
    {
      title: RoutesConfig.SELLER_NEWS.name,
      route: RoutesConfig.SELLER_NEWS.path,
      icon: "mdi-newspaper-variant",
      counter: uiModule.state.unreadNewsMessageCount,
    },
    {
      title: RoutesConfig.SELLER_LESSONS.name,
      route: RoutesConfig.SELLER_LESSONS.path,
      icon: "mdi-calendar-month",
    },
    {
      title: RoutesConfig.SELLER_REPORTS.name,
      route: RoutesConfig.SELLER_REPORTS.path,
      icon: "mdi-trending-up",
      counter: uiModule.state.incompleteReportsCount,
    },
    {
      title: RoutesConfig.SELLER_PAYOUTS.name,
      route: RoutesConfig.SELLER_PAYOUTS.path,
      icon: "mdi-currency-usd",
    },
    {
      title: RoutesConfig.SELLER_PROFILE.name,
      route: RoutesConfig.SELLER_PROFILE.getPath(userModule.state.userId),
      icon: "mdi-account",
      actionText: `${userModule.getters.getFullName()}`,
    },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
    // {
    //   title: RoutesConfig.SELLER_ACHIEVEMENTS.name,
    //   route: RoutesConfig.SELLER_ACHIEVEMENTS.path,
    //   icon: "mdi-trophy",
    // },
    {
      title: RoutesConfig.SELLER_RESOURCES.name,
      route: RoutesConfig.SELLER_RESOURCES.path,
      icon: "mdi-space-invaders",
    },
    {
      title: RoutesConfig.VIDEOS.name,
      route: RoutesConfig.VIDEOS.path,
      icon: "mdi-movie-play",
    },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
  ];

  const customerRoutes: MenuItems = [
    {
      title: RoutesConfig.CUSTOMER_DASHBOARD.name,
      route: RoutesConfig.CUSTOMER_DASHBOARD.path,
      icon: "mdi-view-dashboard",
    },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
    {
      title: RoutesConfig.CUSTOMER_LESSONS.name,
      route: RoutesConfig.CUSTOMER_LESSONS.path,
      icon: "mdi-calendar-month",
    },
    {
      title: RoutesConfig.CUSTOMER_REPORTS.name,
      route: RoutesConfig.CUSTOMER_REPORTS.path,
      icon: "mdi-trending-up",
    },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
    {
      title: RoutesConfig.VIDEOS.name,
      route: RoutesConfig.VIDEOS.path,
      icon: "mdi-movie-play",
    },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
    {
      title: RoutesConfig.CUSTOMER_MEMBERSHIP.name,
      route: RoutesConfig.CUSTOMER_MEMBERSHIP.path,
      icon: "mdi-crown",
      counter: uiModule.state.unpaidInvoices,
    },
  ];

  const studentRoutes: MenuItems = [
    {
      title: RoutesConfig.STUDENT_DASHBOARD.name,
      route: RoutesConfig.STUDENT_DASHBOARD.path,
      icon: "mdi-view-dashboard",
    },
    // {
    //   title: RoutesConfig.ACHIEVEMENTS.name,
    //   route: RoutesConfig.ACHIEVEMENTS.path,
    //   icon: "mdi-trophy",
    //   counter: userModule.getters.getUnreadAchievements(),
    // },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
    {
      title: RoutesConfig.STUDENT_LESSONS.name,
      route: RoutesConfig.STUDENT_LESSONS.path,
      icon: "mdi-calendar-month",
    },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
    {
      title: RoutesConfig.VIDEOS.name,
      route: RoutesConfig.VIDEOS.path,
      icon: "mdi-movie-play",
    },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
  ];

  if (userModule.state.user.betaTester) {
    commonRoutes.unshift({
      title: RoutesConfig.SELLER_ACHIEVEMENTS.name,
      route: RoutesConfig.SELLER_ACHIEVEMENTS.path,
      icon: "mdi-trophy",
    });
  }

  if (uiModule.state.teamCount) {
    customerRoutes.push({
      title: RoutesConfig.CUSTOMER_STUDENTS.name,
      route: RoutesConfig.CUSTOMER_STUDENTS.path,
      icon: "mdi-account-supervisor",
    });
  }

  return showSellerRoutes
    ? sellerRoutes.concat(commonRoutes)
    : showStudentRoutes
      ? studentRoutes.concat(commonRoutes)
      : customerRoutes.concat(commonRoutes);
};
