import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/membership";

export async function listReceipts(userId: string): Promise<Learnlink.Membership.Receipt[]> {
  const receiptsRes = await learnlinkAPI.axios.get<Learnlink.Membership.Receipt[]>(
    `${ENDPOINT}/receipts/${userId}`,
  );
  return receiptsRes.data;
}

export async function getNumberOfUnpaidInvoices(userId: string): Promise<number> {
  const res = await learnlinkAPI.axios.get<number>(
    `${ENDPOINT}/receipts/unpaidCount/${userId}`
  );
  return res.data;
}

export async function getMembership(userId: string): Promise<Learnlink.Membership.default> {
  const membershipRes = await learnlinkAPI.axios.get<Learnlink.Membership.default>(
    `${ENDPOINT}/${userId}`,
  );
  return membershipRes.data;
}

export async function getProduct(productID: string): Promise<Learnlink.Membership.Product> {
  const productRes = await learnlinkAPI.axios.get<Learnlink.Membership.Product>(
    `${ENDPOINT}/product/${productID}`,
  );
  return productRes.data;
}

export async function cancelMembership(userId: string): Promise<Learnlink.Membership.default> {
  const membershipRes = await learnlinkAPI.axios.delete<Learnlink.Membership.default>(
    `${ENDPOINT}/${userId}`,
  );
  return membershipRes.data;
}

export async function pauseMembership(userId: string): Promise<Learnlink.Membership.default> {
  const membershipRes = await learnlinkAPI.axios.post<Learnlink.Membership.default>(
    `${ENDPOINT}/pause/${userId}`,
  );
  return membershipRes.data;
}

export async function listPlans(productID: string): Promise<Learnlink.Membership.Plan[]> {
  const plansRes = await learnlinkAPI.axios.get<Learnlink.Membership.Plan[]>(
    `${ENDPOINT}/plans/${productID}`
  );
  return plansRes.data.sort((a, b) => (a.amount - b.amount));
}

export async function listPlansForEndUser(): Promise<Learnlink.Membership.Plan[]> {
  const plansRes = await learnlinkAPI.axios.get<Learnlink.Membership.Plan[]>(
    `${ENDPOINT}/plansForEndUser`
  );
  return plansRes.data;
}

export async function listProducts(): Promise<Learnlink.Membership.Product[]> {
  const productsRes = await learnlinkAPI.axios.get<Learnlink.Membership.Product[]>(
    `${ENDPOINT}/products`
  );
  return productsRes.data;
}

export async function updateMembership(customerUID: string, plan: string): Promise<Learnlink.Membership.default> {
  const membershipRes = await learnlinkAPI.axios.put<Learnlink.Membership.default>(
    `${ENDPOINT}/${customerUID}/plan/${plan}`
  );
  return membershipRes.data;
}

export async function createMembership(customerUID: string, plan: string): Promise<Learnlink.Membership.default> {
  const membershipRes = await learnlinkAPI.axios.post<Learnlink.Membership.default>(
    `${ENDPOINT}/${customerUID}/${plan}`
  );
  return membershipRes.data;
}

export async function getCollectionMethod(uid: string): Promise<Learnlink.Stripe.CollectionMethod> {
  const stripeRes = await learnlinkAPI.axios.get<Learnlink.Stripe.CollectionMethod>(`${ENDPOINT}/collectionMethod/${uid}`);
  return stripeRes.data;
}

export async function updateCollectionMethod(uid: string, collectionMethod: Learnlink.Stripe.CollectionMethod): Promise<Learnlink.Stripe.CollectionMethod> {
  const stripeRes = await learnlinkAPI.axios.put<Learnlink.Stripe.CollectionMethod>(`${ENDPOINT}/collectionMethod/${uid}/${collectionMethod}`);
  return stripeRes.data;
}
