import { getAnonymousId } from "@/services/analytics";
import publicIp from "public-ip";
import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";
import { ROUTES } from "./routes";

export async function createSellerAccount(seller: Learnlink.Seller.Create): Promise<string> {
  const usersRes = await learnlinkAPI.axios.post<string>(
    ROUTES.CREATE_SELLER,
    {
      ...seller,
      registrationIP: await publicIp.v4(),
      anonymousID: getAnonymousId(),
    });
  return usersRes.data;
}
