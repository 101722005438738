




















































































































import { getTeamSessionStartTimesForOnboardingByCourse } from "@/api/teamSessionService";
import { capitalize } from "@/helpers/stringUtils";
import useAsyncData from "@/hooks/useAsyncData";
import { useRouter } from "@/router";
import Moment from "@/services/moment";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "AvailableCoursePicker",
  setup() {
    const vm = reactive({
      weekday: "" as "mandag" | "tirsdag" | "onsdag" | "torsdag",
    });

    const { currentRoute } = useRouter();
    const { courseID } = currentRoute.value.params;
    const { isLoading, data: teamSessionsStartTimes } = useAsyncData(() => getTeamSessionStartTimesForOnboardingByCourse(courseID));

    function getReadableDate(timestamp: number): string {
      return capitalize(Moment.unix(timestamp).format("dddd DD. MMMM"));
    }

    function getReadableTime(timestamp: number): string {
      return Moment.unix(timestamp).format("HH:mm");
    }

    function getUniqueSessionDatesAsTimestamps(date: "mandag" | "tirsdag" | "onsdag" | "torsdag"): number[] {
      const startTimes = teamSessionsStartTimes.value?.map((startTime) => {
        const weekday = Moment.unix(startTime).format("dddd");
        return weekday === date ? Moment.unix(startTime).startOf("day").unix() : 0;
      }).filter(s => s) || [];
      return [...new Set(startTimes)];
    }

    function getUniqueSessionTimestamps(timestamp: number): number[] {
      return teamSessionsStartTimes.value?.map((startTime) => {
        const startOfDay = Moment.unix(timestamp).startOf("day").unix();
        const endOfDay = Moment.unix(timestamp).endOf("day").unix();
        return startTime >= startOfDay && startTime <= endOfDay ? startTime : 0;
      }).filter(t => t) || [];
    }

    function chooseTime(timestamp: number) {
      window.open(`https://app.learnlink.no/team-bestilling/${timestamp}/${courseID}`, "_blank");
    }

    function contactMe(): void {
      window.open("https://learnlink.no/kontakt-meg-om-kurs", "_blank");
    }

    return {
      chooseTime,
      contactMe,
      isLoading,
      teamSessionsStartTimes,
      getReadableDate,
      getReadableTime,
      getUniqueSessionTimestamps,
      getUniqueSessionDatesAsTimestamps,
      vm,
    };
  }
});
