export enum ROUTES {
  ACCOUNT = "/accounts/*",
  APPROVE_APPLICATION = "/app/e2e/profiles/approveApplication/*",
  APPROVE_INTERVIEW = "/app/e2e/profiles/approveInterview/*",
  APPROVE_TRAINING = "/app/e2e/profiles/approveTraining/*",
  AUTH = "/auth/*",
  CONTACT_ME = "/app/customers/contact-me",
  CREATE_CUSTOMER = "/createCustomer/webflow",
  CREATE_ONE_LESSON = "/app/lessons/0",
  CREATE_TEN_LESSONS = "/app/lessons/10",
  CREATE_SELLER = "/createSeller",
  CREATE_STUDENT = "/app/customers/student",
  CREATE_TEAM = "/app/e2e/team",
  GET_STUDENT = "/app/customers/student/*",
  MEMBERSHIP = "/app/membership/*",
  MESSAGES = "/app/messages",
  ORDER_SELLER = "/app/customers/order-seller",
  PROFILE = "/app/profiles/*",
  RATE_LESSON = "/lessons/rate/*",
  SEND_FIRST_REPORT = "/reports/first",
  SEND_PERIODICAL_REPORT = "/reports/periodical",
  SEND_REGULAR_REPORT = "/reports/",
  TEAMS = "/app/teams/*",
  USER = "/users/*",
}
