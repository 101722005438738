
























































































































import { auth } from "@/services/firebase";
import { createContactRequest } from "@/api/customerService";
import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { RoutesConfig } from "@/router/Routes";
import { SalesTimesWeekdaysPickerItems, Weekday } from "@/helpers/timeUtils";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import ButtonPicker from "@/components/onboarding/ButtonPicker.vue";
import ContactInformationForm from "@/components/onboarding/ContactInformationForm.vue";
import environment from "@/config/environment";
import handleError from "@/helpers/errors";
import PrevNextButtons from "@/components/onboarding/PrevNextButtons.vue";
import TermsOfUse from "@/components/terms-of-use/TermsOfUse.vue";
import WeekdayPicker from "@/components/shared/WeekdayPicker.vue";

import analytics, { getAnonymousId } from "@/services/analytics";
import Learnlink from "@learnlink/interfaces";
import axios from "axios";

export default defineComponent({
  name: "ContactMe",
  components: {
    PrevNextButtons,
    ButtonPicker,
    ContactInformationForm,
    WeekdayPicker,
    TermsOfUse,
  },
  setup() {
    const notify = useNotifier();
    const { navigate, currentRoute } = useRouter();
    const vm = reactive({
      consent: false,
      contactInformation: {
        customerType: "" as Learnlink.Type.CustomerType,
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
        studentName: "",
        valid: false,
      } as Learnlink.Customer.ContactInformation,
      loading: false,
      step: 1,
      targetGroup: "" as Learnlink.Project.TargetGroup,
      weekdays: [] as Weekday[],
    });

    let registrationURL: string;

    onMounted(() => {
      analytics.track(`contactMeStep${vm.step}`);
      registrationURL = currentRoute.value.fullPath;

      const targetGroup = currentRoute.value.params.targetGroup;
      if (targetGroup) {
        if (targetGroup === "barneskole") {
          vm.targetGroup = "elementary";
          next();
        }
        else if (targetGroup === "ungdomskole") {
          vm.targetGroup = "middle";
          next();
        }
        else if (targetGroup === "vgs") {
          vm.targetGroup = "high";
          next();
        }
        else if (targetGroup === "kodekurs") {
          vm.targetGroup = "code";
          next();
        }
      }
    });

    const targetGroup = ref("" as Learnlink.Project.TargetGroup);

    watch(targetGroup, () => {
      vm.targetGroup = targetGroup.value;
      next();
    });

    function next(): void {
      vm.step++;

      // WARNING: Possible issues when sending a nested object.
      analytics.track(`contactMeStep${vm.step}`, vm);
    }

    let anonymousID: string;
    try {
      anonymousID = getAnonymousId();
    }
    catch (e) {
      handleError(e);
    }

    async function submit() {
      vm.loading = true;
      const info = vm.contactInformation;
      const { valid, ...contactInformation } = info;
      if (valid) {
        try {
          await createContactRequest({
            ...contactInformation,
            anonymousID,
            channel: "contactMe",
            registrationURL,
            targetGroup: vm.targetGroup,
            weekdays: vm.weekdays,
          });
          next();

          await auth.signInWithEmailAndPassword(vm.contactInformation.email, vm.contactInformation.password);
          navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
        }
        catch (e) {
          handleError(e);

          if (axios.isAxiosError(e) && e.response?.status === 409) {
            notify({
              title: "E-posten er allerede i bruk! 🤯",
              message: "Du kan logge inn på app.learnlink.no.",
              type: "error",
            });
          }
          else {
            notify({
              title: "Registrering feilet! 🤯",
              message: "Noe gikk galt under registreringen av informasjonen. Vennligst kontakt support dersom problemet vedvarer.",
              type: "error",
            });
          }
        }
      }
      else {
        notify({
          title: "Noe mangler! 🤯",
          message: "Skjemaet er mangelfullt. Vennligst fyll inn det som mangler.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    const targetGroups: Learnlink.PickerItems.default[] = [
      {
        ID: "elementary",
        icon: "mdi-bus-school",
        name: "Barneskole",
      },
      {
        ID: "middle",
        icon: "mdi-book-open-variant",
        name: "Ungdomskole",
      },
      {
        ID: "high",
        icon: "mdi-school",
        name: "Videregående",
      },
      {
        ID: "code",
        icon: "mdi-laptop",
        name: "Kodekurs (6-13 år)",
      },
    ];

    function toLogin(): void {
      if (environment.firebase.projectId === "learnlink-prod") {
        window.location.href = "https://app.learnlink.no";
      }
      else if (environment.firebase.projectId === "learnlink-stag") {
        window.location.href = "https://app.staging.learnlink.no";
      }
    }

    function toReadMore(): void {
      window.location.href = "https://www.learnlink.no/var-metode";
    }

    return {
      next,
      SalesTimesWeekdaysPickerItems,
      submit,
      targetGroup,
      targetGroups,
      toLogin,
      toReadMore,
      vm,
    };
  },
});
