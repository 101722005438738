
















































import { auth } from "@/services/firebase";
import { defineComponent, reactive } from "@vue/composition-api";
import { useNotifier } from "@/providers/notifier";
import { getAuthErrorNotification } from "@/helpers/errors";
import analytics from "@/services/analytics";

export default defineComponent({
  name: "LoginForm",
  setup() {
    const notify = useNotifier();

    const vm = reactive({
      email: "",
      loading: false,
      password: "",
    });

    async function signInUsingEmail() {
      vm.loading = true;
      const sanitizedEmail = vm.email.replace(/\s/g, "");
      try {
        await auth.signInWithEmailAndPassword(sanitizedEmail, vm.password);
        analytics.track("emailSignin");
      }
      catch (e) {
        notify(getAuthErrorNotification(e));
      }

      vm.loading = false;
    }

    return {
      signInUsingEmail,
      vm
    };
  },
});
