







































































import { useNotifier } from "@/providers/notifier";
import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import rules, { Rule } from "@/helpers/formRules";
import { auth } from "@/services/firebase";
import { useRouter } from "@/router";
import { RoutesConfig } from "@/router/Routes";
import { createParent } from "@/api/customerService";
import handleError from "@/helpers/errors";

export default defineComponent({
  name: "ContactInformationFormStepper",
  setup() {
    const notify = useNotifier();
    const { navigate, currentRoute } = useRouter();

    interface Field {
      step: number;
      name: string;
      prop: string;
      rules: Rule[];
      type: string;
    }

    const fields: Field[] = [
      { step: 1, name: "Fornavn", prop: "firstName", rules: [rules.name], type: "text" },
      { step: 2, name: "Etternavn", prop: "lastName", rules: [rules.name], type: "text" },
      { step: 3, name: "E-postadresse", prop: "email", rules: [rules.email], type: "text" },
      { step: 4, name: "Telefonnummer", prop: "phone", rules: [rules.phone], type: "text" },
      { step: 5, name: "Velg et passord", prop: "password", rules: [rules.min], type: "password" },
    ];

    const textfield = ref(null);

    const vm = reactive({
      customer: {
        courseID: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        teamTime: 0,
      },
      loading: false,
      step: 1,
      valid: false,
    });

    function focusField(): void {
      const ignoreFirstElemIndex = vm.step - 1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const fieldRef = textfield.value[ignoreFirstElemIndex];
      fieldRef.focus();
    }

    async function next(rule: Rule, value: string): Promise<void> {
      const check = rule(value);
      if (check !== true) {
        notify({
          title: "Feil! 🤯",
          message: "Sørg for at feltet er riktig fylt ut!",
          type: "error",
        });
      }
      else if (vm.step === 5) {
        await submit();
      }
      else {
        vm.step++;
        setTimeout(() => focusField(), 400);
      }
    }

    function previous(): void {
      vm.step > 1 && vm.step--;
    }

    onMounted(() => {
      setTimeout(() => focusField(), 600);
      vm.customer.teamTime = +currentRoute.value.params.timestamp;
      vm.customer.courseID = currentRoute.value.params.courseID;
    });

    function buttonRules(rule: Rule, value: string): boolean {
      const check = rule(value);
      if (check !== true) return false;
      return check;
    }

    async function submit() {
      vm.loading = true;
      try {
        const { teamID } = await createParent(vm.customer);
        await auth.signInWithEmailAndPassword(vm.customer.email, vm.customer.password);
        if (teamID) {
          notify({
            title: "Gratulerer med plass på kurs! 🥳",
            message: "Husk å legge til barn, og å lese gjennom 'hvordan komme i gang!'",
            type: "success",
          });
          navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
        }
        else {
          notify({
            title: "Noe gikk galt! 🤯",
            message: "Det ser ut til at noen kom deg i forkjøpet og at det ikke er noen plasser igjen på dette tidspunktet. 😱" +
            "Sjekk ut kurskatalogen vår og finn et nytt tidspunkt, eller ta kontakt med oss på support@learnlink.no eller på chat!",
            type: "error",
          });
          navigate(RoutesConfig.CUSTOMER_COURSE_SHOP.path);
        }
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Noe gikk galt! 🤯",
          message: "Ta kontakt med support dersom problemet vedvarer.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    return {
      buttonRules,
      fields,
      next,
      previous,
      rules,
      submit,
      textfield,
      vm
    };
  }
});
