import Learnlink from "@learnlink/interfaces";

export const negativeReasons = [
  "low attendance",
  "technical problems for seller",
  "technical problems for student",
  "low engagement",
  "error in course",
  "high difference in student levels",
  "other"
] as Learnlink.Feedback.negativeReason[];

export const negativeReasonsLocale = [
  "Dårlig oppmøte",
  "Tekniske problemer (Pro)",
  "Tekniske problemer (Elev)",
  "Lavt engasjement (Elev)",
  "Feil i kursprogram",
  "Sprik i nivå (Elev)",
  "annet"
];

export const ratingSymbols = ["😡", "🙁", "😐", "😊", "🤩"];

export const ratingText = ["Elendig", "Ikke så bra", "Helt OK", "Bra", "Helt supert"];

export enum negativeReasonTitle {
  "low attendance" = "Er det noe mer vi burde vite?",
  "technical problems for seller" = "Beskriv det du slet med",
  "technical problems for student" = "Hvilke av deltagerne slet med tekniske problemer?",
  "low engagement" = "Kan du utdype hvorfor du opplever lavt engasjement hos deltagerne?",
  "error in course" = "Beskriv feilen",
  "high difference in student levels" = "Er det noe mer vi burde vite?",
  "other" = "Hva kunne gått bedre?",
}
