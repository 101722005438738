import { getAnonymousId } from "@/services/analytics";
import { ROUTES } from "./routes";
import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";
import publicIp from "public-ip";

const ENDPOINT = "/app/customers";

export async function createCustomerAccount(customer: Learnlink.Customer.Create): Promise<string> {
  const usersRes = await learnlinkAPI.axios.post<{ uid: string }>(
    ROUTES.CREATE_CUSTOMER,
    {
      ...customer,
      registrationIP: publicIp.v4(),
      anonymousID: getAnonymousId(),
    });
  return usersRes.data.uid;
}

export async function createContactRequest(customer: Learnlink.Customer.Create & Partial<Learnlink.Project.Create>): Promise<string> {
  const accountsRes = await learnlinkAPI.axios.post<{ uid: string }>(
    ROUTES.CONTACT_ME,
    customer,
  );
  return accountsRes.data.uid;
}

export async function createOrderRequest(customer: Learnlink.Customer.Create & Partial<Learnlink.Project.Create>): Promise<string> {
  const accountsRes = await learnlinkAPI.axios.post<{ uid: string }>(
    ROUTES.ORDER_SELLER,
    customer,
  );
  return accountsRes.data.uid;
}

export async function createParent(customer: Learnlink.Customer.Create): Promise<{ uid: string, teamID: string }> {
  const usersRes = await learnlinkAPI.axios.post<{ uid: string, teamID: string }>(
    `${ENDPOINT}/`,
    {
      ...customer,
      registrationIP: publicIp.v4(),
      anonymousID: getAnonymousId(),
    });
  return usersRes.data;
}

export async function createStudent(student: Learnlink.Student.Create): Promise<string> {
  const studentRes = await learnlinkAPI.axios.post<{ uid: string }>(
    ROUTES.CREATE_STUDENT,
    student,
  );
  return studentRes.data.uid;
}

export async function getStudent(studentUID: string): Promise<Learnlink.Student.default> {
  const studentRes = await learnlinkAPI.axios.get<Learnlink.Student.default>(
    `${ENDPOINT}/student/${studentUID}`
  );
  return studentRes.data;
}

export async function getStudens(parentUID: string): Promise<Learnlink.Student.MinimalViewApp> {
  const studentRes = await learnlinkAPI.axios.get<Learnlink.Student.MinimalViewApp>(
    `${ENDPOINT}/students/${parentUID}`
  );
  return studentRes.data;
}

export async function createFeedback(feedback: Learnlink.Feedback.Create): Promise<void> {
  await learnlinkAPI.axios.post<void>(
    `${ENDPOINT}/feedback/${feedback.uid}`, feedback
  );
}
