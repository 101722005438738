import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/projects";

export async function getProject(projectID: string): Promise<Learnlink.Project.default> {
  const projectRes = await learnlinkAPI.axios.get<Learnlink.Project.default>(
    `${ENDPOINT}/${projectID}`
  );
  return projectRes.data;
}

export async function getActiveProjects(userType: Learnlink.Type.UserType, uid: string): Promise<Learnlink.Project.FullViewApp[]> {
  const projectsRes = await learnlinkAPI.axios.get<Learnlink.Project.FullViewApp[]>(`${ENDPOINT}/active/${userType}/${uid}`);
  return projectsRes.data;
}

export async function getClosedProjects(userType: Learnlink.Type.UserType, uid: string): Promise<Learnlink.Project.FullViewApp[]> {
  const projectsRes = await learnlinkAPI.axios.get<Learnlink.Project.FullViewApp[]>(`${ENDPOINT}/closed/${userType}/${uid}`);
  return projectsRes.data;
}

export async function completeProjectBySeller(
  projectID: string,
  accomplishments: string,
  improvementAreas: string,
  studentContinuing: boolean,
  terminationReason: string,
  weekdays: Learnlink.Type.Weekday[],
): Promise<Learnlink.Project.FullViewApp> {
  const projectRes = await learnlinkAPI.axios.put(
      `${ENDPOINT}/completedBySeller/${projectID}`, {
        projectID,
        accomplishments,
        improvementAreas,
        studentContinuing,
        terminationReason,
        weekdays,
      }
  );
  return projectRes.data;
}

export async function saveStudentName(projectID: string, studentName: string): Promise<Learnlink.Project.FullViewApp> {
  const projectRes = await learnlinkAPI.axios.put<Learnlink.Project.FullViewApp>(
    `${ENDPOINT}/${projectID}/studentName`, { studentName }
  );
  return projectRes.data;
}

export async function postOverrideRoomURLforProject(projectID: string, URL: string): Promise<Learnlink.Project.FullViewApp> {
  const projectRes = await learnlinkAPI.axios.put<Learnlink.Project.FullViewApp>(
    `${ENDPOINT}/${projectID}/overrideRoomURL`,
    { url: URL }
  );
  return projectRes.data;
}

export async function getProjectCount(userId: string): Promise<number> {
  const projectRes = await learnlinkAPI.axios.get<number>(
    `${ENDPOINT}/count/${userId}`
  );
  return projectRes.data;
}
