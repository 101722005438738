import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import NotFound from "@/views/NotFound.vue";
import { BaseRoutes, RoutesConfig } from "./Routes";
import { InjectionKey, provide, inject, watch, Ref, UnwrapRef, reactive, toRef } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import authModule from "@/store/modules/auth";
import { useModule } from "@/store/hooks";

Vue.use(VueRouter);

const isFallbackRoute = (path: string) => path === "*";

const routes: Array<RouteConfig> = [
  ...Object.values(RoutesConfig).map(route => ({
    name: route.name,
    path: route.path,
    component: route.component,
  })),
  {
    path: "*",
    component: NotFound,
  },
];

type RouterInterface = {
  navigate: (location: string) => void;
  currentRoute: Ref<UnwrapRef<Route>>;
  getCurrentBaseRoute: () => BaseRoutes;
  navigation: {
    navigateToEditProfile: () => void;
    navigateToStudentDashboard: () => void;
    navigateToTutorDashboard: () => void;
  }
  refresh: () => void;
};

const RouterSymbol: InjectionKey<VueRouter> = Symbol("router");

export function provideRouter(router: VueRouter): void {
  provide(RouterSymbol, router);
}

export const mapNewRouteToOldRoute = (location: string): string => {
  if (location.startsWith(BaseRoutes.TUTOR)) {
    return location.replace(BaseRoutes.TUTOR, "");
  }
  else {
    return location;
  }
};

export const useRouter = (): RouterInterface => {
  const router = inject(RouterSymbol);
  if (!router) {
    throw new Error("Router not provided");
  }

  const navigate = (location: string) => {
    router.push(location);
  };

  function refresh() {
    router?.go(0);
  }

  const getCurrentBaseRoute = () => {
    if (router.currentRoute.path.startsWith(BaseRoutes.STUDENT)) {
      return BaseRoutes.STUDENT;
    }
    else {
      return BaseRoutes.TUTOR;
    }
  };

  return {
    navigate,
    navigation: {
      navigateToStudentDashboard() {
        navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
      },
      navigateToTutorDashboard() {
        navigate(RoutesConfig.SELLER_DASHBOARD.path);
      },
      navigateToEditProfile() {
        if (getCurrentBaseRoute() === BaseRoutes.TUTOR) {
          navigate(RoutesConfig.SELLER_SETTINGS.path);
        }
        else {
          navigate(RoutesConfig.CUSTOMER_SETTINGS.path);
        }
      },
    },
    currentRoute: toRef(reactive(router), "currentRoute"),
    getCurrentBaseRoute,
    refresh,
  };
};

export const gucciRouter = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/**
 * Redirect user to correct base route depending on their role
 */
const handleFallbackPath = (to: Route): string | undefined => {
  if (to.path === "/") {
    if (userModule.state.user.seller) {
      return BaseRoutes.TUTOR;
    }
    else if (userModule.state.user.role === "student") {
      return BaseRoutes.STUDENT;
    }
    else {
      return BaseRoutes.PARENT;
    }
  }
  else if ((to.path.includes(BaseRoutes.STUDENT) || to.path.includes(BaseRoutes.TUTOR)) && userModule.state.user.role === "parent") {
    return BaseRoutes.PARENT;
  }
  else if ((to.path.includes(BaseRoutes.STUDENT) || to.path.includes(BaseRoutes.PARENT)) && userModule.state.user.role === "seller") {
    return BaseRoutes.TUTOR;
  }
  else if ((to.path.includes(BaseRoutes.PARENT) || to.path.includes(BaseRoutes.TUTOR)) && userModule.state.user.role === "student") {
    return BaseRoutes.STUDENT;
  }
  else if (to.matched.length === 1 && isFallbackRoute(to.matched[0].path) && !to.path.includes("not-found")) {
    if (userModule.state.user.seller) {
      return `${BaseRoutes.TUTOR}/not-found`;
    }
    else {
      return `${BaseRoutes.STUDENT}/not-found`;
    }
  }
};

gucciRouter.beforeEach((to, from, next) => {
  try {
    next(handleFallbackPath(to));
  }
  catch (e) {
    const unsubscribe = watch(() => useModule(authModule).state.isAuthorized, (isAuthorized) => {
      if (isAuthorized === true) {
        next(handleFallbackPath(to));
        unsubscribe();
      }
    });
  }
});
