








































































import { useNotifier } from "@/providers/notifier";
import { defineComponent, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import rules, { Rule } from "@/helpers/formRules";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ContactInformationFormStepper",
  props: {
    value: {
      type: Object as PropType<Learnlink.Customer.ContactInformation>,
      required: true
    }
  },
  setup(props, { emit }) {
    const _value = reactive(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const notify = useNotifier();

    interface Field {
      step: number;
      name: string;
      prop: string;
      rules: Rule[];
      type: string;
    }

    const fields: Field[] = [
      { step: 1, name: "Forelders fornavn", prop: "firstName", rules: [rules.name], type: "text" },
      { step: 2, name: "Forelders etternavn", prop: "lastName", rules: [rules.name], type: "text" },
      { step: 3, name: "Elevens fornavn", prop: "studentName", rules: [rules.name], type: "text" },
      { step: 4, name: "Din e-postadresse", prop: "email", rules: [rules.email], type: "text" },
      { step: 5, name: "Ditt telefonnummer", prop: "phone", rules: [rules.phone], type: "text" },
      { step: 6, name: "Velg et passord", prop: "password", rules: [rules.min], type: "password" },
    ];

    const textfield = ref(null);

    const vm = reactive({
      repeatPassword: "",
      showPassword: false,
      showRepeatPassword: false,
      step: 1
    });

    function focusField(): void {
      const ignoreFirstElemIndex = vm.step - 1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const fieldRef = textfield.value[ignoreFirstElemIndex];
      fieldRef.focus();
    }

    function next(rule: Rule, value: string): void {
      const check = rule(value);
      if (check === true) {
        vm.step++;
        if (vm.step === 7) emit("success");
        else setTimeout(() => focusField(), 400);
      }
      else {
        notify({
          title: "Feil! 🤯",
          message: "Sørg for at feltet er riktig fylt ut!",
          type: "error",
        });
      }
    }

    function previous(): void {
      vm.step--;
      if (vm.step === 0) emit("previous");
    }

    onMounted(() => {
      setTimeout(() => focusField(), 600);
    });

    function buttonRules(rule: Rule, value: string): boolean {
      const check = rule(value);
      if (check !== true) return false;
      return check;
    }

    return {
      _value,
      buttonRules,
      fields,
      next,
      previous,
      rules,
      textfield,
      vm
    };
  }
});
