






















































































































import rules from "@/helpers/formRules";
import { defineComponent, PropType, reactive, watchEffect } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ContactInformationForm",
  props: {
    value: {
      type: Object as PropType<Learnlink.Customer.ContactInformation>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = reactive(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      repeatPassword: "",
      showPassword: false,
      showRepeatPassword: false,
    });

    return {
      _value,
      rules,
      vm,
    };
  },
});
