import { capitalize } from "@/helpers/stringUtils";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";

export function formatUnixTimestamp(unixTimestamp: number, format: string): string {
  return Moment.unix(unixTimestamp).format(format);
}

export function timestampRangeToHours(startTime: number, endTime: number): string {
  return String(Math.round((endTime - startTime) / 3600 * 100) / 100).replace(".", ",");
}

export function getReadableTimeForTeam(dates: Learnlink.TeamSession.default[], teamTime: string): string {
  if (!dates.length) return "Foreløpig er ingen datoer satt";
  const sortedDates = dates.sort();
  return `${capitalize(Moment.unix(sortedDates[0].startTime).format("dddd"))}er klokken ${teamTime}, fra ${Moment.unix(sortedDates[0].startTime).format("D. MMMM")}`;
}

export function stringTimeToUnix(date: string, time: string): number {
  const _date = Moment(date).format("YYYY-MM-DD");
  return Moment(`${_date} ${time}`, "YYYY-MM-DD HH:mm").unix();
}

export type Weekday =
  "monday" |
  "tuesday" |
  "wednesday" |
  "thursday" |
  "friday" |
  "saturday" |
  "sunday";

export enum Weekdays {
  monday = "Mandager",
  tuesday = "Tirsdager",
  wednesday = "Onsdager",
  thursday = "Torsdager",
  friday = "Fredager",
  saturday = "Lørdager",
  sunday = "Søndager",
}

export const weekdays = [
  {
    value: "monday",
    text: "Mandager",
  },
  {
    value: "tuesday",
    text: "Tirsdager",
  },
  {
    value: "wednesday",
    text: "Onsdager",
  },
  {
    value: "thursday",
    text: "Torsdager",
  },
  {
    value: "friday",
    text: "Fredager",
  },
  {
    value: "saturday",
    text: "Lørdager",
  },
  {
    value: "sunday",
    text: "Søndager",
  },
];

export const LessonTimes = "15 og 20";

export const LessonTimesWeekdaysPickerItems = [
  {
    value: "monday",
    text: `${Weekdays.monday} mellom ${LessonTimes}`,
  },
  {
    value: "tuesday",
    text: `${Weekdays.tuesday} mellom ${LessonTimes}`,
  },
  {
    value: "wednesday",
    text: `${Weekdays.wednesday} mellom ${LessonTimes}`,
  },
  {
    value: "thursday",
    text: `${Weekdays.thursday} mellom ${LessonTimes}`,
  },
  {
    value: "friday",
    text: `${Weekdays.friday} mellom ${LessonTimes}`,
  },
];

export const SalesTimes = "09 og 19";

export const SalesTimesWeekdaysPickerItems = [
  {
    value: "monday",
    text: `${Weekdays.monday} mellom ${SalesTimes}`,
  },
  {
    value: "tuesday",
    text: `${Weekdays.tuesday} mellom ${SalesTimes}`,
  },
  {
    value: "wednesday",
    text: `${Weekdays.wednesday} mellom ${SalesTimes}`,
  },
  {
    value: "thursday",
    text: `${Weekdays.thursday} mellom ${SalesTimes}`,
  },
  {
    value: "friday",
    text: `${Weekdays.friday} mellom ${SalesTimes}`,
  },
];

export function mapWeekday(weekday: string): string {
  return Weekdays[weekday as Weekday];
}
