









































































































































































import { auth } from "@/services/firebase";
import { createOrderRequest } from "@/api/customerService";
import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { LessonTimesWeekdaysPickerItems, Weekday } from "@/helpers/timeUtils";
import { RoutesConfig } from "@/router/Routes";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import analytics, { getAnonymousId } from "@/services/analytics";
import ButtonPicker from "@/components/onboarding/ButtonPicker.vue";
import ContactInformationFormStepper from "@/components/onboarding/ContactInformationFormStepper.vue";
import environment from "@/config/environment";
import handleError from "@/helpers/errors";
import PrevNextButtons from "@/components/onboarding/PrevNextButtons.vue";
import TermsOfUse from "@/components/terms-of-use/TermsOfUse.vue";
import WeekdayPicker from "@/components/shared/WeekdayPicker.vue";
import Learnlink from "@learnlink/interfaces";
import axios from "axios";

type ReadableCategory = "code" | "math" | "english" | "norwegian" | "science";

export default defineComponent({
  components: {
    ButtonPicker,
    PrevNextButtons,
    WeekdayPicker,
    TermsOfUse,
    ContactInformationFormStepper,
  },
  name: "AutomaticOnboarding",
  setup() {
    const notify = useNotifier();
    const { navigate, currentRoute } = useRouter();

    const vm = reactive({
      category: "",
      consent: false,
      contactInformation: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
        studentName: "",
        valid: false,
      } as Learnlink.Customer.ContactInformation,
      customerType: "",
      interests: "",
      level: "",
      loading: false,
      readableCategoryStep2: "" as ReadableCategory,
      readableCategoryStep3: "" as ReadableCategory,
      step: 1,
      targetGroup: "" as Learnlink.Project.TargetGroup,
      weekdays: [] as Weekday[],
    });

    let registrationURL: string;

    onMounted(() => {
      analytics.track(`automaticOnboardingStep${vm.step}`);
      registrationURL = currentRoute.value.fullPath;

      const targetGroup = currentRoute.value.params.targetGroup;
      if (targetGroup) {
        if (targetGroup === "barneskole") {
          vm.targetGroup = "elementary";
          next(vm.targetGroup);
        }
        else if (targetGroup === "ungdomskole") {
          vm.targetGroup = "middle";
          next(vm.targetGroup);
        }
        else if (targetGroup === "vgs") {
          vm.targetGroup = "high";
          next(vm.targetGroup);
        }
      }

      const customerType = currentRoute.value.params.customerType;
      if (customerType) {
        if (customerType === "matte") {
          vm.customerType = "math";
        }
        else if (customerType === "koding") {
          vm.customerType = "code";
        }
        next(vm.customerType);
      }
    });

    const targetGroup = ref("" as Learnlink.Project.TargetGroup);
    const readableCategoryStep2 = ref("" as ReadableCategory);
    const readableCategoryStep3 = ref("" as ReadableCategory);

    watch(targetGroup, () => {
      vm.targetGroup = targetGroup.value;
      next(vm.targetGroup);
    });
    watch(readableCategoryStep2, () => {
      vm.readableCategoryStep2 = readableCategoryStep2.value;
      next(vm.readableCategoryStep2);
    });
    watch(readableCategoryStep3, () => {
      vm.readableCategoryStep3 = readableCategoryStep3.value;
      next(vm.readableCategoryStep3);
    });

    function next(selected = ""): void {
      if (selected === "code") vm.step = 5;
      else if (selected === "math") vm.step = 4;
      else vm.step++;

      // WARNING: Possible issues when sending a nested object.
      analytics.track(`automaticOnboardingStep${vm.step}`, vm);
    }

    function prev(): void {
      if (vm.step === 5 && vm.readableCategoryStep2 === "code") vm.step = 2;
      else if (vm.step === 4) vm.step = 2;
      else vm.step--;
    }

    let anonymousID: string;
    try {
      anonymousID = getAnonymousId();
    }
    catch (e) {
      handleError(e);
    }

    function getCategory(readableCategory: ReadableCategory) {
      let category = "";
      if (readableCategory === "code") category = "160";
      else if (vm.targetGroup === "elementary") {
        switch (readableCategory) {
          case "math": category = "124"; break;
          case "english": category = "74"; break;
          case "norwegian": category = "73"; break;
          case "science": category = "72"; break;
        }
      }
      else if (vm.targetGroup === "middle") {
        switch (readableCategory) {
          case "math": category = "125"; break;
          case "english": category = "134"; break;
          case "norwegian": category = "135"; break;
          case "science": category = "133"; break;
        }
      }
      else if (vm.targetGroup === "high") {
        switch (readableCategory) {
          case "math": category = "145"; break;
          case "english": category = "152"; break;
          case "norwegian": category = "151"; break;
          case "science": category = "153"; break;
        }
      }
      return category;
    }

    async function submit() {
      vm.loading = true;
      const info = vm.contactInformation;
      const { valid, ...contactInformation } = info;
      vm.category = vm.readableCategoryStep3 ? getCategory(vm.readableCategoryStep3) : getCategory(vm.readableCategoryStep2);
      if (valid) {
        try {
          await createOrderRequest({
            anonymousID,
            channel: "automaticOnboarding",
            category: vm.category,
            level: vm.level,
            registrationURL,
            targetGroup: vm.targetGroup,
            weekdays: vm.weekdays,
            ...contactInformation,
          });
          next();
          await auth.signInWithEmailAndPassword(vm.contactInformation.email, vm.contactInformation.password);
          navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
        }
        catch (e) {
          handleError(e);
          if (axios.isAxiosError(e) && e.response?.status === 409) {
            notify({
              title: "E-posten er allerede i bruk! 🤯",
              message: "Du kan logge inn på app.learnlink.no.",
              type: "error",
            });
          }
          else {
            notify({
              title: "Registrering feilet! 🤯",
              message: "Noe gikk galt under registreringen av informasjonen. Vennligst kontakt support dersom problemet vedvarer.",
              type: "error",
            });
          }
        }
      }
      else {
        notify({
          title: "Noe mangler! 🤯",
          message: "Noe av informasjonen vi trenger mangler, eller er fylt ut feil. Vennligst dobbeltsjekk at all informasjon stemmer.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    const generalCategories: Learnlink.PickerItems.default[] = [
      {
        ID: "math",
        icon: "mdi-calculator-variant",
        name: "Matte"
      },
      {
        ID: "code",
        icon: "mdi-xml",
        name: "Koding",
      },
      {
        ID: "other",
        icon: "mdi-dots-horizontal",
        name: "Annet"
      }
    ];

    const specificCategories: Learnlink.PickerItems.default[] = [
      {
        ID: "norwegian",
        icon: "mdi-map-marker",
        name: "Norsk"
      },
      {
        ID: "english",
        icon: "mdi-earth",
        name: "Engelsk"
      },
      {
        ID: "science",
        icon: "mdi-flask",
        name: "Realfag",
      },
      {
        ID: "other",
        icon: "mdi-dots-horizontal",
        name: "Annet"
      }
    ];

    const targetGroups: Learnlink.PickerItems.default[] = [
      {
        ID: "elementary",
        icon: "mdi-bus-school",
        name: "Barneskole",
      },
      {
        ID: "middle",
        icon: "mdi-book-open-variant",
        name: "Ungdomskole",
      },
      {
        ID: "high",
        icon: "mdi-school",
        name: "Videregående",
      }
    ];

    function toFrontPage(): void {
      window.location.href = "https://learnlink.no";
    }

    function toLogin(): void {
      if (environment.firebase.projectId === "learnlink-prod") {
        window.location.href = "https://app.learnlink.no";
      }
      else if (environment.firebase.projectId === "learnlink-stag") {
        window.location.href = "https://app.staging.learnlink.no";
      }
    }

    return {
      generalCategories,
      LessonTimesWeekdaysPickerItems,
      next,
      prev,
      readableCategoryStep2,
      readableCategoryStep3,
      specificCategories,
      submit,
      targetGroup,
      targetGroups,
      toFrontPage,
      toLogin,
      vm
    };
  }
});
