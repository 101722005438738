





















import { defineComponent, inject } from "@vue/composition-api";
import MainMenu from "@/components/app/layout/MainMenu.vue";
import AppBarButtons from "@/components/app/layout/AppBarButtons.vue";

export default defineComponent({
  name: "AuthorizedRoot",
  components: {
    MainMenu,
    AppBarButtons,
  },
  setup() {
    const proOnboardingActive = inject("proOnboardingActive");

    return {
      proOnboardingActive
    };
  }
});
