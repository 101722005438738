import { dynamicModule } from "../dynamicModuleUtil";
import { Getters, Mutations, Actions } from "vuex-smart-module";
import { getProfile, updateAccount, updateProfile, updateUser } from "@/api/userService";
import Learnlink from "@learnlink/interfaces";

interface UserStateInterface {
  userId: string;
  account: Learnlink.Account.default;
  profile: Learnlink.Profile.default;
  user: Learnlink.User.default;
}

class UserGetters extends Getters<UserStateInterface> {
  getFullName() {
    return `${this.state.profile.firstName} ${this.state.profile.lastName}`;
  }

  getUnreadAchievements() {
    return this.state.user.achievements?.filter(achievement => !achievement.read).length;
  }
}
class UserMutations extends Mutations<UserStateInterface> {
  setAccount(payload: Learnlink.Account.default) {
    this.state.account = payload;
  }

  setProfile(payload: Learnlink.Profile.default) {
    this.state.profile = payload;
  }

  setUserObject(payload: Learnlink.User.default) {
    this.state.user = payload;
  }

  setUserId(userId: string) {
    this.state.userId = userId;
  }
}

class UserActions extends Actions<
  UserStateInterface,
  UserGetters,
  UserMutations,
  UserActions
> {
  async fetchProfile(uid: string) {
    const profile = await getProfile(uid);
    this.mutations.setProfile(profile);
  }

  async updateUserAccount(accountUpdate: Partial<Learnlink.Account.default>) {
    const updatedAccount = await updateAccount({
      uid: this.state.userId,
      ...accountUpdate,
    });
    this.mutations.setAccount(updatedAccount);
  }

  async updateUserProfile(profileUpdate: Partial<Learnlink.Profile.default>) {
    const updatedProfile = await updateProfile({
      uid: this.state.userId,
      ...profileUpdate,
    });
    this.mutations.setProfile(updatedProfile);
  }

  async updateUserObject(userUpdate: Partial<Learnlink.User.default>) {
    const updatedUserObject = await updateUser({
      uid: this.state.userId,
      ...userUpdate,
    });
    this.mutations.setUserObject(updatedUserObject);
  }
}

export const userModule = dynamicModule<UserStateInterface, UserGetters, UserMutations, UserActions>(
  "dynamicUser", {
    getters: UserGetters,
    mutations: UserMutations,
    actions: UserActions,
  });
