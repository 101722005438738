
























import { useRouter } from "@/router";
import { RoutesConfig } from "@/router/Routes";
import { defineComponent } from "@vue/composition-api";
import { auth } from "@/services/firebase";

export default defineComponent({
  name: "AppBarButtons",
  setup() {
    const { navigate } = useRouter();
    return {
      async signOut() {
        auth.signOut();
        navigate(RoutesConfig.LOGIN.path);
      },
    };
  },
});
