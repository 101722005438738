


















































import { computed, defineComponent } from "@vue/composition-api";
import { getMenuItems } from "@/components/app/layout/MenuItems";
import { userModule } from "@/store/modules/user";
import { useRouter } from "@/router";
import AppBarButtons from "@/components/app/layout/AppBarButtons.vue";

export default defineComponent({
  name: "MainMenu",
  components: {
    AppBarButtons,
  },
  setup() {
    const { navigate, currentRoute } = useRouter();
    const items = computed(() => getMenuItems(userModule.state.user.seller, userModule.state.user.role === "student"));
    const selectedItem = computed(() =>
      items.value
        .filter(i => !i.divider)
        .findIndex(({ route }) => route.match(currentRoute.value.path)) || 0);

    function navigateToRoute(route: string) {
      if (route !== currentRoute.value.path) navigate(route);
    }
    return {
      currentRoute,
      items,
      name: `${userModule.state.profile.firstName} ${userModule.state.profile.lastName}`,
      navigateToRoute,
      selectedItem,
    };
  },
});
