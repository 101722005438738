


























































































import { auth as firebaseAuth } from "./services/firebase";
import { computed, defineComponent, provide, reactive, ref } from "@vue/composition-api";
import { configureScope } from "@sentry/vue";
import { gucciRouter, provideRouter, useRouter } from "./router";
import { identifyUser } from "./services/analytics";
import { loadDynamicModules } from "./store/dynamicModules";
import { login } from "./api/authService";
import { Notification, provideNotifier } from "./providers/notifier";
import { RoutesConfig } from "./router/Routes";
import { uiModule } from "@/store/modules/ui";
import { useModule } from "./store/hooks";
import { userModule } from "@/store/modules/user";
import AppBar from "@/components/app/layout/AppBar.vue";
import authModule from "./store/modules/auth";
import AuthorizedRoot from "./components/app/AuthorizedRoot.vue";
import AutomaticOnboarding from "@/views/onboarding/AutomaticOnboarding.vue";
import AutomaticTeamOnboarding from "@/views/onboarding/AutomaticTeamOnboarding.vue";
import ContactMe from "@/views/onboarding/ContactMe.vue";
import Feedback from "@/views/Feedback.vue";
import handleError from "./helpers/errors";
import Login from "@/views/Login.vue";
import LogRocket from "logrocket";
import Moment from "./services/moment";
import Register from "@/views/onboarding/sellers/Register.vue";
import SellerFeedback from "@/views/seller/SellerFeedback.vue";
import store from "./store";
import stripe from "@/services/stripe";
import AvailableCoursePicker from "./views/onboarding/AvailableCoursePicker.vue";

export default defineComponent({
  name: "App",
  components: {
    SellerFeedback,
    Feedback,
    AppBar,
    AuthorizedRoot,
    AutomaticOnboarding,
    AutomaticTeamOnboarding,
    AvailableCoursePicker,
    ContactMe,
    Login,
    Register,
  },
  setup() {
    const isLoading = ref(true);
    const { state, mutations } = useModule(authModule);
    provideRouter(gucciRouter);
    provideNotifier((notification) => notify(notification));
    const { currentRoute, navigate, refresh } = useRouter();

    const vm = reactive({
      notification: {
        title: "",
        message: "",
        type: "success",
      } as Notification,
      alertVisible: false
    });

    function notify(notification: Notification) {
      vm.notification = notification;
      vm.alertVisible = true;
    }

    firebaseAuth.onAuthStateChanged(async(user) => {
      if (user) {
        identifyUser(user.uid);
        configureScope((scope) => {
          scope.setUser({
            id: user.uid,
          });
        });

        try {
          await Promise.all([
            loadDynamicModules(store, user.uid),
            stripe.setup()
          ]);
          mutations.authorize(user.uid);
          isLoading.value = false;
          if (window.location.hostname !== "localhost") {
            LogRocket.identify(user.uid, {
              betaTester: userModule.state.user.betaTester,
              role: userModule.state.user.role,
              seller: userModule.state.user.seller
            });
          }

          if (userModule.state.user.seller) uiModule.actions.fetchIncompleteReportsCount();
          else {
            await Promise.all([
              uiModule.actions.fetchTeamCount(),
              uiModule.actions.fetchProjectCount(),
              userModule.state.user.role === "parent" && uiModule.actions.fetchUnpaidInvoices(),
            ]);
          }

          if (!state.simulate) {
            await login(user.uid);
          }
          return uiModule.actions.fetchUnreadNewsMessageCount();
        }
        catch (e) {
          handleError(e);
          notify({
            title: "Kunne ikke logge inn",
            message: "Brukeren er ugyldig. Prøv å logge inn med en annen bruker.",
            type: "error",
          });
          mutations.deAuthorize();
          firebaseAuth.signOut();
          navigate(RoutesConfig.LOGIN.path);
        }
      }
      else {
        mutations.deAuthorize();
        isLoading.value = false;
      }
    });

    const proOnboardingActive = computed(() => {
      if (userModule.state.user.seller &&
        userModule.state.user.registerDate > Moment("2021-07-01").unix()) {
        return !userModule.state.profile.applicationApproved;
      }
      return false;
    });

    function loginLoaderActive(val: boolean) {
      isLoading.value = val;
    }

    provide("loginLoaderActive", loginLoaderActive);
    provide("proOnboardingActive", proOnboardingActive);

    const simulationInfo = computed(() => {
      return state.simulate ? `${userModule?.state.profile.firstName} ${userModule?.state.profile.lastName} ${userModule?.state.userId}` : "";
    });

    function stopSimulation() {
      navigate(RoutesConfig.SELLER_DASHBOARD.path);
      refresh();
    }

    return {
      currentRoute,
      isAuthorized: computed(() => state.isAuthorized),
      isLoading,
      proOnboardingActive,
      RoutesConfig,
      simulationInfo,
      state,
      stopSimulation,
      vm
    };
  },
});
